import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"
import confetti from "canvas-confetti"
import { useEffect } from "react"

const Thanks = ({ location }) => {
  const data = useStaticQuery(graphql`
    query ThanksQuery {
      image: file(absolutePath: { regex: "/thanks.jpeg/" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      site {
        siteMetadata {
          author {
            name
          }
          social {
            twitter
          }
        }
      }
    }
  `)

  useEffect(() => {
    confetti({
      particleCount: 100,
      spread: 70,
      origin: { y: 0.6 },
    })
  }, [])

  const siteTitle = data.site.siteMetadata.author.name

  return (
    <>
      <Layout location={location} title={siteTitle}>
        <SEO title="Thanks for subscribing!" />
        <h1
          style={{
            fontFamily: "Montserrat, Arial Black, sans-serif",
            fontWeight: 900,
            marginTop: rhythm(1),
            marginBottom: rhythm(1),
          }}
        >
          You are all set{" "}
          <span role="img" aria-label="raised hands">
            🙌
          </span>
        </h1>
        <p>
          Thanks for subscribing! Your email is now confirmed. I will send you a
          message when the next article comes out.
        </p>
        <p>
          <strong>In the meantime</strong>, if you'd like to get some daily
          visual React content, go ahead and{" "}
          <a href="https://twitter.com/asidorenko_">follow me on Twitter</a>.
        </p>
      </Layout>
    </>
  )
}

export default Thanks
